function DOMready(callback) {
  document.readyState !== 'loading'
    ? callback()
    : document.addEventListener('DOMContentLoaded', callback)
}

DOMready(function () {
  const spinContainer = document.querySelector('.interactive-spinner')
  const spinBtns = document.querySelectorAll('.spin-button')
  const spinWheel = document.querySelector('.interactive-spinner__dial')
  const cards = document.querySelectorAll('.interactive-card')
  let activeStep = 1

  if (spinContainer !== null) {
    spinBtns.forEach((spinBtn) => {
      spinBtn.addEventListener('click', (e) => {
        const lengthOfSpin = window
          .getComputedStyle(spinWheel)
          .getPropertyValue('--spin-time')
        const startingDeg = Number(spinWheel.dataset.currDeg) || 0
        const randDeg = startingDeg + Math.round(600)

        spinContainer.classList.add('is-spinning')
        spinWheel.style.transform = `rotate(${randDeg}deg)`

        spinWheel.dataset.currDeg = randDeg

        setTimeout(() => {
          spinContainer.classList.remove('is-spinning')

          if (cards.length > 0) {
            const totalSteps = cards.length

            cards.forEach((el) => {
              const card = el

              card.classList.remove('js-show')
              card.classList.add('hidden')
            })

            ++activeStep

            if (activeStep <= totalSteps) {
              cards[activeStep - 1].classList.add('js-show')
              cards[activeStep - 1].classList.remove('hidden')
            } else {
              activeStep = 1
              cards[activeStep - 1].classList.add('js-show')
              cards[activeStep - 1].classList.remove('hidden')
            }
          }
        }, lengthOfSpin)
      })
    })
  }
})
